
.screen-dialog,
.screen-sr-dialog,
.screen-sr-new-dialog {
  max-width: 100% !important;
  /* overflow: hidden; */
  /* width:100% !important */
}

.screen-sr-dialog .mat-dialog-container {
  position: relative;
  max-width: none;
  /* width: 90rem; */
  width: 95vw;
  background-color: #fff;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
  color: #000;
  /* height: 40rem;
    max-height: 40rem; */
  height: 95vh;
}

.screen-sr-new-dialog .mat-dialog-container {
  width: 50rem;
  position: relative;
}

.screen-sr-new-dialog .mat-dialog-content {
  width: 100%;
}

.screen-dialog .mat-dialog-container {
  position: relative;
  max-width: none;
  width: 50rem;
  background-color: #fff;
  box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.075);
  color: #000;
}

.screen-dialog .mat-dialog-actions,
.screen-sr-new-dialog .mat-dialog-actions {
  justify-content: flex-end;
  margin: 1rem 0;
}

.screen-dialog .btn-complete-upload {
  margin-right: 0.3rem;
}

.screen-dialog .close-dialog {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  outline: none;
  color: #0257b7;
  cursor: pointer;
}

.screen-dialog .mat-dialog-content,
.screen-sr-dialog .mat-dialog-content {
  padding: 0 1rem 0 0;
  margin: 0;
  position: relative;
  max-height: 100%;
}

.screen-dialog .mat-dialog-content .dialog-exit,
.screen-sr-dialog .mat-dialog-content .dialog-exit,
.screen-sr-new-dialog .mat-dialog-content .dialog-exit {
  position: absolute;
  top: 0;
  right: 0;
}

.dialog-search-location {
  width: 100%;
}



.showCancelCaseMsg {
  position: absolute;
  /* text-align: right; */
  top: 3rem;
  right: 0.8rem;
  font-weight: bold;
}

.feedback {
  color: #e94c55;
}

.nav-search .btn-search-submit {
  top: 0.5rem;
}

.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  border: 1px solid;
  bottom: 2.5rem;
  left: 18rem;
  right: 0.8rem;
  /* margin-left: -60px; */
  position: absolute;
  z-index: 1;
}

.tooltiptext::after {
  content: " ";
  position: absolute;
  top: 3.9rem;
  left: 10rem;
  /* margin-left: 1rem; */
  border-width: 0.5rem;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.case-cancel .item-input:hover .tooltiptext {
  visibility: visible;
}

.nav-search input {
  padding-left: 2.5rem;
}

.nav-search .btn-search-submit {
  top: 0;
}

.nav-search .btn-search-submit img {
  padding-top: 0.4rem;
}

.nav-search .btn-search-submit .mat-progress-spinner {
  top: 0.2rem;
}

.btn-search-submit.disabled,
.btn-search-submit:disabled {
  background-color: #e5e7e8;
  color: #7a7a7a;
  cursor: default;
}

.screen-dialog .mat-dialog-content,
.screen-sr-dialog .mat-dialog-content {
  overflow: initial;
}

.quickSearchErrorInline {
  position: absolute;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  text-align: right;
}

.page-case-details .case-history {
  margin-top: 3rem;
  /* added to have space around while the cancel case msg appears*/
}

html {
  overflow-y: scroll;
  /*fix to remove the width on scroll being added*/
}

.page-create-request .block-5 .has-radio {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

footer {
  padding: 0.5rem 1.5rem 0.7rem;
}

.page-reports-search .card.time-period {
  position: relative;
}

.filterBtn {
  position: absolute;
  right: 0.8rem;
  top: 4.5rem;
}

.filterBtn .date-range-item {
  display: flex;
  flex-direction: row-reverse;
}

.page-create-request input[readonly],
.page-create-request input[-moz-read-only],
.disableSelect {
  background-color: lightgrey !important;
  cursor: default !important;
  color: rgba(24, 31, 44, 0.5) !important;
  border-color: rgba(229, 231, 232, 0.75) !important;
}

.page-reports-search .row > .card.scrolling {
  position: relative;
  overflow: hidden;
}

[hidden] {
  display: none !important;
}

.page-reports-search .date-range .item-input input {
  width: 9rem;
}

.page-reports-search .date-range {
  margin-top: 2rem;
  align-items: center;
}

.page-reports-search .timePeriodCls .item-input select {
  width: 9rem;
}

.is-required:after {
  content: " *";
  color: #e94c55;
}

a:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: rgba(94, 92, 92, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.header-account .account-link a:hover {
  text-decoration: none;
  cursor: none;
}

.page-reports-search .mat-pseudo-checkbox {
  background-color: transparent;
  width: 17px !important;
  height: 17px !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.54);
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
}

.page-reports-search .mat-pseudo-checkbox-checked {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.54);
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
}

.page-reports-search .mat-pseudo-checkbox::after {
  color: #006ce6;
}

.page-reports-search .mat-pseudo-checkbox-checked::after {
  top: 1px;
  left: 0px;
}

.page-reports-search .mat-selection-list {
  margin-top: 8px !important;
  padding: 0 !important;
  max-height: 160px;
  overflow-y: scroll;
}

.page-reports-search .mat-list-text {
  font-size: 1rem;
  padding: 0 0.5rem !important;
}

.page-reports-search .mat-list-option {
  height: 27px !important;
  line-height: 13px !important;
}

.page-reports-search .mat-list-item-content-reverse {
  padding: 0 !important;
  flex-direction: row !important;
}

select {
  padding-right: 0;
}

.page-error {
  padding-bottom: 4rem;
}

.page-error .description {
  margin: 0.5rem 0 0 0;
}

.page-error .feedback {
  margin-left: 1rem;
}

.mat-datepicker-content-touch {
  overflow: hidden !important;
}

.mat-calendar {
  width: 50vh !important;
  height: 55vh !important;
}

.is-mat-date-picker input::-ms-clear {
  display: none;
}

.timeoutMsg {
  color: #e94c55;
  margin: 0 0.6rem;
}

.dashboard-item-input {
  position: relative;
  display: flex;
}

.dashboard-vehicle-radio {
  width: 100%;
  margin-bottom: 4px;
}

.dashboard-position {
  position: relative;
}

.dashboard-display {
  display: flex;
}

.dashboard-location-type {
  align-items: center !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  display: flex;
  margin-left: 30px;
}

.dashboard-location-margin {
  margin-right: 12px;
}

.dashboard-distance-textbox {
  width: 100px !important;
  border-style: none none solid !important;
  margin-right: 16px !important;
}

.dashboard-damage-icons {
  border-color: black;
  display: inline-block;
  outline: 0;
  border: 1px solid #006ce6;
  width: 42pt;
  height: 42pt;
  box-shadow: none;
  text-shadow: none;
  transition: none;
  border-radius: 50%;
}

.dashboard-damage-icons.selected {
  background-color: #006ce6;
}

.dashboard-vehicle-damage-icon {
  border-color: rgb(0, 108, 230);
  display: inline-block;
  outline: 0;
  background-color: #fff;
  border: 1px solid #006ce6;
  width: 36pt;
  height: 36pt;
  box-shadow: none;
  text-shadow: none;
  transition: none;
  border-radius: 50%;
}

.h2-vehicle-damage {
  font-size: 1.5rem;
  line-height: 1.1em;
  font-weight: 400;
  padding: 0;
  margin: 13px;
  color: rgb(103, 156, 218) !important;
}

/* .dashboard-damage-icons:hover{
    border-color: rgb(0, 108, 230);
    background-color: rgb(0, 108, 230);
    src:"./images/ic_accident.svg";
} */

.dashboard-icons {
  width: 100%;
  margin: 10pt 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  text-align: center;
  cursor: pointer;
}

.dashboard-icons-label {
  display: block !important;
  color: black;
  margin: 10pt 0 !important;
  text-align: center !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.dashboard-gap-right-icons {
  margin-right: 5rem;
}

.dashboard-gap-right {
  margin-right: 1.5rem;
  line-height: 2rem;
}

.dashboard-radio-gap-top {
  margin-top: 0.4rem;
}

.dashboard-dropdown {
  border: none !important;
}

.dashboard-radio-gap-left {
  margin-left: 50px;
}

.dashboard-progress-spiner {
  display: inline !important;
  position: absolute !important;
  left: 95%;
  top: 8%;
}

.success-header {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.27px;
  line-height: 25px;
}

.success-icon {
  margin-right: 7px;
  color: #00b975;
}

.dashboard-damage-icons.selected {
  background-color: #006ce6;
}

.flat-tier-img {
  height: 26vh;
}
.flat-img-wrapper {
  position: relative;
  width: min-content;
}
.flat-img-wrapper p {
  position: absolute;
  width: 100px;
}
.flat-img-wrapper .tier-selection {
  width: 27px;
  height: 30px;
  display: inline-block;
  z-index: 1000;
  background: #fff;
  border-radius: 4px;
}
.flat-img-wrapper .tier-selection:hover {
  cursor: pointer;
}
.flat-img-wrapper .tier-name {
  width: 50px;
  display: none;
  text-align: left;
  bottom: 0px;
  position: absolute;
  right: 10px;
  font-weight: bold;
  /* color:#006CE6; */
}
.flat-img-wrapper .selected-tier-con .tier-name {
  display: inline-block;
}
.flat-img-wrapper .front-driver .tier-name,
.flat-img-wrapper .rear-driver .tier-name {
  left: 19%;
}
.flat-img-wrapper .front-driver {
  top: 15%;
  /* left:-96px; */
  right: 99%;
  text-align: right;
}
.flat-img-wrapper .front-pasenger {
  top: 15%;
  /* right:-96px; */
  left: 98%;
}
.flat-img-wrapper .rear-driver {
  bottom: 10%;
  /* left:-96px; */
  right: 99%;
  text-align: right;
}
.flat-img-wrapper .rear-pasenger {
  bottom: 19%;
  /* right:-96px; */
  left: 99%;
}
.flat-img-wrapper .tier-selection .material-icons {
  position: absolute;
  top: 3px;
  right: 3px;
  display: none;
  color: #fff;
}

.flat-img-wrapper .tier-selection.selected-tier .material-icons {
  display: inline-block;
}
.flat-img-wrapper .front-pasenger .tier-selection .material-icons,
.flat-img-wrapper .rear-pasenger .tier-selection .material-icons {
  left: 3px;
}

.checkbox-gap-top {
  margin-top: 15px;
}

.desktop-question-label {
  width: 100%;
  max-width: 55%;
  min-width: min-content;
}

.desktop-question-label.disbleSubmit {
  color: #e94c55;
  max-width: 100% !important;
}

.dashboard-item-title {
  margin-top: 0.33rem;
}

agm-map {
  height: 300px;
}

.dot-pickup {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  background-color: #fd7567;
  margin-left: 40%;
}

.dot-drop {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  background-color: #00e64d;
}
.map-margin {
  margin-top: 5px;
}

.legend-label {
  margin-right: 14px;
  position: relative;
  top: -4px;
}

.showBlock {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: stretch flex-start;
  align-items: stretch;
}

.newClasButton{
  margin: -3px 10px;
}
.page-error {
  width: 60%;
    position: absolute;
    top: 40%;
    left: 20%;
    box-shadow: 1px 1px 4px 2px #ccc;
    text-align: center;
}
.page-error .description {
  font-size: 15px;
  line-height: 20px;
}
/* bootstrap hack: fix content width inside hidden tabs */
.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: block;    /* undo display:none          */
  height: 0;         /* height:0 is also invisible */ 
  overflow: hidden;  /* no-overflow                */
}
.tab-content > .active, .pill-content > .active {
  height: auto;      /* let the content decide it  */
} /* bootstrap hack end */
.mat-dialog-actions
{ 
  .red {
     background: red;
  }
  .green {
    background: green;
  }
}
.popuphead{
  display: inline-flex;
  .material-icons{
    margin-right: 5px;
    color: red;
  }
}
.mat-form-field.reason{
  width: 100%;
  margin-top: 10px;
  .mat-label{
    color: #006ce6;
    font-size: 15px;
  }
}